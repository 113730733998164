<template lang='pug'>
extends ../templates/PageBase.pug
block content
  .container-fluid.container-fixed-lg.bg-white
      .card.card-transparent
        .card-header
          .card-title
            | Imprint
        .card-body
          .row
            .col-lg-6
              strong annoyingdev.com
              p Trung Phan - Dipl. Informatiker (FH)
              p
                | Zachenberger Str. 7
                | D-10318 Berlin
              p Follow me on&nbsp;
                a.social-follow.m-r-10(href='https://www.youtube.com/channel/UC6cz1HBaliqli3z0AAoeHvA')
                  i.fa.fa-youtube
                a.social-follow.m-r-10(href='https://www.linkedin.com/in/trung-phan-869a47130/')
                  i.fa.fa-linkedin
                a.social-follow.m-r-10(href='https://www.xing.com/profile/Trung_Phan2')
                  i.fa.fa-xing
</template>

<script>
  export default {
    name: 'Imprint'
  }
</script>

<style scoped>
.social-follow {
  font-size: 20px;
}
</style>
