import { render, staticRenderFns } from "./Imprint.vue?vue&type=template&id=23e6d514&scoped=true&lang=pug&"
import script from "./Imprint.vue?vue&type=script&lang=js&"
export * from "./Imprint.vue?vue&type=script&lang=js&"
import style0 from "./Imprint.vue?vue&type=style&index=0&id=23e6d514&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23e6d514",
  null
  
)

component.options.__file = "Imprint.vue"
export default component.exports